<!-- eslint-disable vue/no-parsing-error -->
<template>
    <div>
        <div class="product-details" v-if="product">
            <div class="d-flex justify-content-end py-3" v-if="product.brand">
                <img :src="product.brand.logo" :width="100" alt="brand">
            </div>
            <!-- <h1 class="product-title">{{ productName }}</h1> -->
            <div class="d-flex justify-content-between align-items-center gap-6 mb-1">
                <div class="details-filter-row details-row-size">
                    <label for="quantity" class="text-dark">{{ $t('quantity') }}:</label>
                    <quantity-input :product="product" @change-qty="changeQty"></quantity-input>
                </div>
                <div>
                    <div class="product-price mb-0" v-if="stockQty == 0" key="outPrice">
                        <span class="out-price">${{ product.price.toFixed(2) }}</span>
                    </div>

                    <template v-else>
                        <div class="product-price mb-0" v-if="regularPrice == finalPrice">{{ formatCurrency(finalPrice)
                            }}</div>
                        <template v-else>
                            <div class="product-price mb-0 flex-column">
                                <!-- v-if="product.variants.length == 0" -->
                                <span class="old-price">{{ formatCurrency(regularPrice) }}</span>
                                <span class="new-price">{{ formatCurrency(finalPrice) }}</span>
                            </div>
                            <!-- <div class="product-price" v-else>${{ minPrice.toFixed(2) }} - ${{ maxPrice.toFixed(2) }}</div> -->
                        </template>
                    </template>
                </div>
            </div>
            <div class="underline"></div>
            <div class="d-flex justify-content-between my-2">
                <div>
                    <!-- <p class="success">{{ $t('view_everything_from_the_category') }}</p> -->
                    <p class="success" v-if="product.stock_status === 'IN_STOCK'">{{ $t('availability_in_stock') }}</p>
                    <p class="text-danger" v-else>{{ $t('not_available_in_stock') }}</p>
                    <template v-if="colors.length > 0">
                        <div class="details-filter-row details-row-size">
                            <label>{{ $t('color') }}:</label>

                            <div class="product-nav product-nav-dots">
                                <!-- <a href="#"
                                :class="{ active: item.color == selectedVariant.color, disabled: item.disabled }"
                                :style="{ 'background-color': item.color }" v-for="(item, index) in colorArray"
                                :key="index" @click.prevent="selectColor(item)"></a> -->
                                <router-link to=""
                                    :class="{ active: item.color == selectedVariant.color, disabled: item.disabled }"
                                    :style="{ 'background-color': item.color }" v-for="(item, index) in colors"
                                    :key="index"></router-link>
                                <!-- @click.prevent="selectColor(item)" -->
                            </div>
                        </div>
                    </template>
                </div>
                <div class="offer-box" v-if="product.price_tiers.length > 0">
                    <p>Bestel {{ product.price_tiers[0].quantity }} stuks voor <strong>{{
            formatCurrency(product.price_tiers[0].final_price.value) }}</strong> per stuk en
                        <strong>bespaar {{ product.price_tiers[0].discount.percent_off }}%</strong>
                    </p>
                </div>
            </div>
            <div class="cart-button-box">
                <button class="cart-button" @click="addToCart">
                    <font-awesome-icon icon="fa-solid fa-shopping-cart" />
                    {{ $t('add_to_cart') }}
                </button>
            </div>

            <div class="d-flex align-items-center">
                <a href="#" class="btn-product btn-wishlist justify-content-start" title="Wishlist"
                    @click.prevent="updateWish()" key="notInWishlist" v-if="!isInWishlist">
                    <span>{{ $t('add_to_wishlist') }}</span>
                </a>
                <router-link to="/account/" class="btn-product btn-wishlist justify-content-start added-to-wishlist"
                    title="Wishlist" key="inWishlist" v-else>
                    <span>Go to Wishlist</span>
                </router-link>

                <div class="compare d-flex gap-1 pointer" @click="addCompare">
                    <input type="checkbox" :value="isCompare" v-model="isCompare" />
                    <span> {{ $t('compare') }}</span>
                </div>
            </div>

            <div class="underline"></div>

            <!-- <template v-if="product.variants.length > 0">
            <div class="details-filter-row details-row-size">
                <label for="size">Size:</label>
                <div class="select-custom">
                    <select name="size" id="size" class="form-control" v-model="selectedVariant.size"
                        @change="selectSize">
                        <option value="null">Select a size</option>
                        <option :value="item.size" v-for="(item, index) in sizeArray" :key="index">{{ item.size
                            }}
                        </option>
                    </select>
                </div>

                <a href="javascript:;" class="size-guide mr-4">
                    <i class="icon-th-list"></i>size guide
                </a>
                <a href="#" @click.prevent="clearSelection" v-if="showClear">clear</a>
            </div>
            <vue-slide-toggle :open="showVariationPrice">
                <div class="product-price">${{ selectedVariant.price ? selectedVariant.price.toFixed(2) : 0 }}
                </div>
            </vue-slide-toggle>
        </template> -->

            <!-- <div class="ratings-container">
            <div class="ratings">
                <div class="ratings-val" :style="{ width: product.ratings * 20 + '%' }"></div>
                <span class="tooltip-text">{{ product.ratings.toFixed(2) }}</span>
            </div>
            <span class="ratings-text mt-0">( {{ product.review }} Reviews )</span>
        </div> -->


            <div class="product-content">
                <p><strong>{{ $t('Delivery_time') }}:</strong> {{ product.DeliveryTime.long }}</p>
                <div class="underline"></div>
                <ul style="list-style: inside">
                    <li class="text-primary">Nu 5% korting met code: ACTIE</li>
                    <li>Gratis verzending NL vanaf 5,-</li>
                    <li>Gratis cado bij elke besteling</li>
                </ul>
                <div class="underline"></div>
                <p>{{ $t('loyality_points') }}</p>
                <div class="mb-1">
                    <strong>{{ $t('product_information2') }}:</strong>
                    <p>Model: {{ product.sku }}</p>
                </div>
                <p v-html="product.short_description.html" />

                <!-- <p class="text-right mt-1">
                <font-awesome-icon icon="fa-solid fa-share-nodes" class="text-primary" />
                E-mail naar een vriend
            </p> -->
                <b-link class="secondary" :href="`mailto:?body=${getCurrentUrl}`">
                    <p class="text-right mt-1">
                        <font-awesome-icon icon="fa-solid fa-share-nodes" class="text-primary" />
                        {{ $t("email_to_friend") }}
                    </p>
                </b-link>
            </div>

            <!-- <div class="product-details-action">
            <a href="#" class="btn-product btn-cart" @click.prevent="addCart(0)">
                :class="{'btn-disabled': !canAddToCart(product, quantity) || (product.variants.length > 0 && ! showVariationPrice) }"
                <span>add to cart</span>
            </a>

            <div class="details-action-wrapper">
                <a href="#" class="btn-product btn-wishlist" title="Wishlist"
                    @click.prevent="addToWishlist({ product: product })" key="notInWishlist">
                    v-if="! isInWishlist(product)"
                    <span>Add to Wishlist</span>
                </a>
                <router-link
                    to=""
                    class="btn-product btn-wishlist added-to-wishlist"
                    title="Wishlist"
                    v-else
                    key="inWishlist"
                >
                    <span>Go to Wishlist</span>
                </router-link>
            </div>
        </div>

        <div class="product-details-footer">
            <div class="product-cat w-100 text-truncate">
                <span>Category:</span>
                <span v-for="(cat, index) of product.category" :key="index">
                    <router-link to="">{{ cat.name }}</router-link>
                    <span>{{ index < product.category.length - 1 ? ',' : '' }}</span>
                    </span>
            </div>

            <div class="social-icons social-icons-sm">
                <span class="social-label">Share:</span>
                <a href="javascript:;" class="social-icon" title="Facebook" target="_blank">
                    <i class="icon-facebook-f"></i>
                </a>
                <a href="javascript:;" class="social-icon" title="Twitter" target="_blank">
                    <i class="icon-twitter"></i>
                </a>
                <a href="javascript:;" class="social-icon" title="Instagram" target="_blank">
                    <i class="icon-instagram"></i>
                </a>
                <a href="javascript:;" class="social-icon" title="Pinterest" target="_blank">
                    <i class="icon-pinterest"></i>
                </a>
            </div>
        </div> -->

            <!-- Cart Popup -->
            <dialog-box width="60%" title="" :isVisible="cartDialog" :handleClose="handleClose">
                <template v-slot:default>
                    <product-list-view :product="product" type="cart" @handleClose="handleClose" />

                    <!-- related_products -->
                    <product-section v-if="relatedProducts && relatedProducts.length > 0" type="similar_products"
                        :products="relatedProducts" />
                    <!-- related_products -->

                    <!-- new_in_the_range -->
                    <product-section v-if="newInTheRange" type="new_in_the_range" :products="newInTheRange" />
                    <!-- new_in_the_range -->
                </template>
            </dialog-box>
            <!-- Cart Popup -->

            <!-- Compare Popup -->
            <dialog-box width="45%" title="" :isVisible="compareDialog" :handleClose="handleCompareDialogClose">
                <template v-slot:default>
                    <product-list-view :product="product" type="compare"
                        :heading="$t('product_added_comparison_list_msg')" @handleClose="handleCompareDialogClose" />
                </template>
            </dialog-box>
            <!-- Compare Popup -->
        </div>
        <div v-else>
            {{ $t("Product not found or out of stock") }}
        </div>
    </div>
</template>
<script>
import { mapGetters } from "vuex";
import QuantityInput from '@/esf_weert_mobilesupplies/core/components/elements/QuantityInput';
import ProductListView from '@/esf_weert_mobilesupplies/core/components/elements/products/ProductListView';
import DialogBox from '@/esf_weert_mobilesupplies/core/components/elements/DialogBox';
import ProductSection from '@/esf_weert_mobilesupplies/core/components/partial/home/ProductSection';
import { isServer } from "@storefront/core/helpers";

export default {
    components: {
        QuantityInput,
        ProductListView,
        ProductSection,
        DialogBox
    },
    props: {
        // product: {
        //     type: Object,
        //     required: true
        // },
        selectedConfigProduct: Object,
    },
    data: function () {
        return {
            variationGroup: [],
            selectableGroup: [],
            sizeArray: [],
            colorArray: [],
            selectedVariant: {
                color: null,
                colorName: null,
                price: null,
                size: null
            },
            // maxPrice: this.product.price_range.maximum_price.regular_price.value,
            // minPrice: this.product.price_range.maximum_price.final_price.value,
            quantity: 1,
            cartDialog: false,
            compareDialog: false,
            colors: [
                {
                    color: "#cc9966",
                    color_name: "Brown",
                },
                {
                    color: "#3399cc",
                    color_name: "Blue",
                },
                {
                    color: "#9966cc",
                    color_name: "Purple",
                },
                {
                    color: "#333333",
                    color_name: "Black",
                },
                {
                    color: "#669933",
                    color_name: "Green",
                },
                {
                    color: "#cc3333",
                    color_name: "Red",
                },
                {
                    color: "#999999",
                    color_name: "Grey",
                },
                {
                    color: "#c0c0c0",
                    color_name: "Silver",
                },
                {
                    color: "#EE7D21",
                    color_name: "Orange",
                },
                {
                    color: "#FEDC32",
                    color_name: "Yellow",
                },
            ],
        };
    },
    computed: {
        ...mapGetters({
            product: "product/getCurrentProduct",
            productConfig: "product/getCurrentConfig",
            productBundles: "product/getCurrentBundle",
            selectedBundles: "product/getSelectedBundles",
            options: "product/getCurrentProductOptions",
        }),
        // ...mapGetters('cart', ['canAddToCart']),
        // ...mapGetters('wishlist', ['isInWishlist']),
        // ...mapGetters('compare', ['isInCompare']),
        productName() {
            return this.selectedConfigProduct
                ? this.selectedConfigProduct.name
                : this.product.name;
        },
        isInWishlist() {
            return this.$store.getters["wishlist/isProductInWishlist"](
                this.product.sku
            );
        },
        newInTheRange() {
            return this.$store.getters["home/getHomeNewSofas"];
        },
        relatedProducts() {
            return this.$store.getters["product/getRelated"]
        },
        getCurrentUrl() {
            if (!isServer) {
                return window.location.href;
            }
            return "";
        },
        compareProducts() {
            return this.$store.getters["product/getProductCompare"];
        },
        isCompare() {
            return this.compareProducts.some((product) => product.sku == this.product.sku)
        },
        currentChildSku() {
            return this.$store.getters["product/getCurrentChildSku"];
        },
        btnDisable() {
            if (this.product.__typename == "ConfigurableProduct") {
                const retval = this.currentChildSku;
                if (retval != null) {
                    return false;
                } else {
                    return true;
                }
            } else {
                return false;
            }
        },
        regularPrice() {
            return this.selectedConfigProduct
                ? this.selectedConfigProduct.price_range.maximum_price.regular_price
                    .value
                : this.product &&
                this.product.price_range.maximum_price.regular_price.value;
        },
        finalPrice() {
            return this.selectedConfigProduct
                ? this.selectedConfigProduct.price_range.maximum_price.final_price.value
                : this.product &&
                this.product.price_range.maximum_price.final_price.value;
        },
        stockQty() {
            return this.selectedConfigProduct
                ? this.selectedConfigProduct.stock_qty
                : this.product.stock_qty;
        },
        // showClear: function () {
        //     return this.selectedVariant.color || this.selectedVariant.size
        //         ? true
        //         : false;
        // },
        // showVariationPrice: function () {
        //     return this.selectedVariant.color && this.selectedVariant.size
        //         ? true
        //         : false;
        // },
        // isCartSticy: function () {
        //     return this.$route.path.includes('/product/default');
        // },
    },
    // created: function () {
    //     let min = this.minPrice;
    //     let max = this.maxPrice;
    //     this.variationGroup = this.product.variants.reduce((acc, cur) => {
    //         cur.size.map(item => {
    //             acc.push({
    //                 color: cur.color,
    //                 colorName: cur.color_name,
    //                 size: item.name,
    //                 price: cur.price
    //             });
    //         });
    //         if (min > cur.price) min = cur.price;
    //         if (max < cur.price) max = cur.price;
    //         return acc;
    //     }, []);

    //     if (this.product.variants.length == 0) {
    //         min = this.product.sale_price
    //             ? this.product.sale_price
    //             : this.product.price;
    //         max = this.product.price;
    //     }

    //     this.minPrice = min;
    //     this.maxPrice = max;

    //     this.refreshSelectableGroup();
    // },
    methods: {
        // ...mapActions('cart', ['addToCart']),
        // ...mapActions('wishlist', ['addToWishlist']),
        // ...mapActions('compare', ['addToCompare']),
        // refreshSelectableGroup: function () {
        //     let tempArray = [...this.variationGroup];
        //     if (this.selectedVariant.color) {
        //         tempArray = this.variationGroup.reduce((acc, cur) => {
        //             if (this.selectedVariant.color !== cur.color) {
        //                 return acc;
        //             }
        //             return [...acc, cur];
        //         }, []);
        //     }

        //     this.sizeArray = tempArray.reduce((acc, cur) => {
        //         if (acc.findIndex(item => item.size == cur.size) !== -1)
        //             return acc;
        //         return [...acc, cur];
        //     }, []);

        //     tempArray = [...this.variationGroup];
        //     if (this.selectedVariant.size) {
        //         tempArray = this.variationGroup.reduce((acc, cur) => {
        //             if (this.selectedVariant.size !== cur.size) {
        //                 return acc;
        //             }
        //             return [...acc, cur];
        //         }, []);
        //     }

        //     this.colorArray = this.product.variants.reduce((acc, cur) => {
        //         if (
        //             tempArray.findIndex(item => item.color == cur.color) == -1
        //         ) {
        //             return [
        //                 ...acc,
        //                 {
        //                     color: cur.color,
        //                     colorName: cur.color_name,
        //                     price: cur.price,
        //                     disabled: true
        //                 }
        //             ];
        //         }
        //         return [
        //             ...acc,
        //             {
        //                 color: cur.color,
        //                 colorName: cur.color_name,
        //                 price: cur.price,
        //                 disabled: false
        //             }
        //         ];
        //     }, []);
        // },
        // selectColor: function (item) {
        //     if (item.color == this.selectedVariant.color) {
        //         this.selectedVariant = {
        //             ...this.selectedVariant,
        //             color: null,
        //             colorName: null,
        //             price: item.price
        //         };
        //     } else {
        //         this.selectedVariant = {
        //             ...this.selectedVariant,
        //             color: item.color,
        //             colorName: item.colorName,
        //             price: item.price
        //         };
        //     }
        //     this.refreshSelectableGroup();
        // },
        // selectSize: function () {
        //     if (this.selectedVariant.size == 'null') {
        //         this.selectedVariant = { ...this.selectedVariant, size: null };
        //     }
        //     this.refreshSelectableGroup();
        // },
        changeQty: function (current) {
            this.quantity = current;
        },
        // clearSelection: function () {
        //     this.selectedVariant = {
        //         ...this.selectedVariant,
        //         color: null,
        //         colorName: null,
        //         size: null
        //     };
        //     this.refreshSelectableGroup();
        // },
        getColor(value) {
            if (this.product.__typename == "ConfigurableProduct") {
                const parameter = this.productConfig.configurable_options.find(
                    (element) => element.attribute_code === "kleur"
                );

                if (parameter != null) {
                    if (this.productConfig.configurable_options.length === 1) {
                        const variant = this.productConfig.variants.find(
                            (el) => el.product.sku === value
                        );

                        const newVal = variant.attributes[0].value_index;
                        const object = parameter.values.find(
                            (element) => element.value_index == newVal
                        );
                        if (object != null) {
                            return object.swatch_data.value;
                        } else {
                            return null;
                        }
                    } else {
                        const object = parameter.values.find(
                            (element) => element.value_index == value
                        );
                        return object.swatch_data.value;
                    }
                } else {
                    return null;
                }
            } else {
                return null;
            }
        },
        async addToCart() {
            this.cartDialog = true;
            switch (this.product.__typename) {
                case "SimpleProduct": {
                    const item =
                        '{data: {sku: "' +
                        this.product.sku +
                        '", quantity:' +
                        this.quantity +
                        "}}  ";
                    let retval = await this.$store.dispatch("cart/addToCart", {
                        type: this.product.__typename,
                        item: item,
                    });

                    if (retval == false) {
                        const msg = {
                            type: "danger",
                            title: this.$t("shopping_basket"),
                            text: this.$t("not_add_product"),
                        };
                        this.$store.dispatch("messages/sendMessage", { message: msg });
                    } else {
                        // this.$bvModal.show("modal-1");
                        this.$root.$emit("openCartPopup");
                    }
                    break;
                }
                case "ConfigurableProduct": {
                    const item =
                        '{ parent_sku: "' +
                        this.product.sku +
                        '" data: {sku: "' +
                        this.currentChildSku +
                        '", quantity:' +
                        this.quantity +
                        "}}  ";
                    let retval = await this.$store.dispatch("cart/addToCart", {
                        type: this.product.__typename,
                        item: item,
                    });
                    if (retval == false) {
                        const msg = {
                            type: "danger",
                            title: this.$t("add_to_cart"),
                            text: this.$t("not_add_product"),
                        };
                        this.$store.dispatch("messages/sendMessage", { message: msg });
                    } else {
                        if (this.$gtm.enabled()) {
                            window.dataLayer.push({
                                event: "eec.add",
                                ecommerce: {
                                    add: {
                                        products: [
                                            {
                                                name: this.product.name,
                                                id: this.product.sku,
                                                quantity: this.quantity,
                                            },
                                        ],
                                    },
                                },
                            });
                        }
                        this.$root.$emit("openCartPopup");
                    }
                    break;
                }
                case "BundleProduct": {
                    const selected_options =
                        this.$store.getters["product/getSelectedBundlesOptions"];

                    let items =
                        "{ quantity: " +
                        this.quantity +
                        ' sku: "' +
                        this.product.sku +
                        '" selected_options: ' +
                        JSON.stringify(selected_options) +
                        " }";

                    const retval = await this.$store.dispatch("cart/addProductToCart", {
                        items: items,
                    });

                    if (this.$gtm.enabled()) {
                        window.dataLayer.push({
                            event: "eec.add",
                            ecommerce: {
                                add: {
                                    products: [
                                        {
                                            name: this.product.name,
                                            id: this.product.sku,
                                            quantity: this.quantity,
                                        },
                                    ],
                                },
                            },
                        });
                        if (this.$gtm.ga4Enabled) {
                            window.dataLayer.push({
                                event: "add_to_cart",
                                ecommerce: {
                                    add: {
                                        currency: "EUR",
                                        value: this.product.final_price,
                                        items: [this.getGTMItems()],
                                    },
                                },
                            });
                        }
                    }
                    if (retval == false) {
                        const msg = {
                            type: "danger",
                            title: this.$t("add_to_cart"),
                            text: this.$t("not_add_product"),
                        };
                        this.$store.dispatch("messages/sendMessage", { message: msg });
                    } else {
                        this.$root.$emit("openCartPopup");
                    }
                    break;
                }
                default:
                    break;
            }
            this.quantity = 1;
        },
        updateWish() {
            if (this.isInWishlist) {
                this.$store.dispatch("wishlist/removeProductFromWishlist", {
                    sku: this.product.sku,
                });
            } else {
                if (this.product.__typename == "SimpleProduct") {
                    this.$store.dispatch("wishlist/addProductToWishlist", {
                        sku: this.product.sku,
                        parentSku: null,
                    });
                } else {
                    const childSku = this.currentChildSku;
                    if (childSku == null) {
                        const msg = {
                            type: "danger",
                            title: this.$t("wishlist error"),
                            text: this.$t("wishlist_select_options"),
                        };
                        this.$store.dispatch("messages/sendMessage", { message: msg });
                    } else {
                        this.$store.dispatch("wishlist/addProductToWishlist", {
                            sku: childSku,
                            parentSku: this.product.sku,
                        });
                        const msg = {
                            type: "success",
                            title: "Add wishlist",
                            text: this.$t("add_product_wishlist"),
                        };
                        this.$store.dispatch("messages/sendMessage", { message: msg });
                    }
                }
            }
        },
        formatCurrency(amount) {
            return this.$helpers.formatCurrency(amount);
        },
        handleClose() {
            this.cartDialog = false;
        },
        handleCompareDialogOpen() {
            this.compareDialog = true;
        },
        handleCompareDialogClose() {
            this.compareDialog = false;
        },
        addCompare: function () {
            if (this.isCompare) {
                this.$store.dispatch("product/removeCompareProduct", { sku: this.product.sku });
                const msg = {
                    type: "success",
                    title: "Success",
                    text: "Product removed from compare list",
                };
                this.$store.dispatch("messages/sendMessage", { message: msg });
                return;
            }
            if (this.compareProducts.length >= 2) {
                const msg = {
                    type: "danger",
                    title: "Error",
                    text: "You can only compare 2 products",
                };
                this.$store.dispatch("messages/sendMessage", { message: msg });
                return;
            }
            this.$store.dispatch("product/addCompareProduct", { sku: this.product.sku })
            this.handleCompareDialogOpen();
        }
    }
};
</script>
<template>
    <div v-if="product">
        <h4 class="text-primary mb-0">{{ productName }}</h4>
        <div class="d-flex justify-content-between align-items-center">
            <p class="pointer" @click="goBack()">
                <font-awesome-icon icon="fa-solid fa-angle-left" />
                {{ $t('view_everything_from_the_category') }}
            </p>
            <div class="position-relative">
                <div class="ratings-container pointer mb-0" @click="handleRating">
                    <div class="ratings mr-2">
                        <div class="ratings-val" :style="{ width: ( product.rating_summary / 100 * 5) * 20 + '%' }"></div>
                        <span class="tooltip-text">{{ product.rating_summary / 100 * 5 }}</span>
                    </div>
                    <font-awesome-icon icon="fa-solid fa-angle-down" />
                    <span class="ratings-text mt-0">( {{ product.review_count }} ) {{ $t('review') }}</span>
                </div>
                <div class="position-absolute" style="z-index: 1; right: 0; top: 2rem;" v-if="ratingDialog">
                    <overall-rating :product="product" />
                </div>
            </div>
        </div>
        <ClientOnly>
            <div class="product-gallery" v-if="product">
                <div class="row m-0">
                    <figure class="product-main-image" @click.prevent="openLightBox">
                        <!-- :width="product.pictures[currentIndex].width" :height="product.pictures[currentIndex].height"  -->
                        <img id="product-zoom" v-lazy="`${product.media_gallery[currentIndex].url}`" alt="product" />
                        <!-- <img id="product-zoom" src="@/esf_weert_mobilesupplies/assets/static/images/products/iphone_14.png" alt=""> -->

                        <!--  -->
                        <a href="#" id="btn-product-gallery" class="btn-product-gallery">
                            <i class="icon-search-plus"></i>
                        </a>
                    </figure>

                    <div id="product-zoom-gallery" class="product-image-gallery flex-grow-1">
                        <a class="product-gallery-item h-100 carousel-dot" href="#"
                            :class="{ active: currentIndex == index }" v-for="(img, index) in product.media_gallery"
                            :key="index" @click.prevent="changePicture(index)">

                            <!-- :width="smPicture.width" :height="smPicture.height" -->
                            <img :src="`${img.url}`" alt="product side" width="50" height="50" />
                            <!-- <img src="@/esf_weert_mobilesupplies/assets/static/images/products/iphone_14.png" alt=""> -->
                        </a>
                    </div>
                    <light-box ref="lightbox" class="light-box" :media="lightBoxMedia" :show-caption="true"
                        :show-light-box="false"></light-box>
                </div>
            </div>
        </ClientOnly>
        <!-- <dialog-box width="50%" title="" :isVisible="" :handleClose="handleRatingClose">
            <template v-slot:default>
                <p>hello</p>
            </template>
</dialog-box> -->
    </div>
</template>
<script>
import ClientOnly from "vue-client-only";
import { isServer } from "@storefront/core/helpers";
import OverallRating from '@/esf_weert_mobilesupplies/core/components/elements/OverallRating';
import 'vue-image-lightbox/dist/vue-image-lightbox.min.css';

export default {
    components: {
        ClientOnly,
        LightBox: () => (!isServer ? import("vue-image-lightbox") : null),
        OverallRating
    },
    props: {
        product: {
            type: Object,
            default: function () {
                return {
                    sm_pictures: [],
                    pictures: []
                };
            }
        }
    },
    data: function () {
        return {
            // baseUrl: baseUrl,
            currentIndex: 0,
            ratingDialog: false
        };
    },
    mounted() {
        document.addEventListener('click', (event) => {
            if (!event.target.closest('.ratings-container')) {
                this.ratingDialog = false;
            }
        });
    },
    computed: {
        lightBoxMedia: function () {
            return this.product.media_gallery.reduce((acc, mediaItem) => {
                return [
                    ...acc,
                    {
                        thumb: mediaItem.url,
                        src: mediaItem.url,
                        caption: mediaItem.label || this.product.name
                    }
                ];
            }, []);
        },
        productName() {
            return this.selectedConfigProduct
                ? this.selectedConfigProduct.name
                : this.product.name;
        },
    },
    methods: {
        changePicture: function (index) {
            this.currentIndex = index;
        },
        openLightBox: function () {
            this.$refs.lightbox.showImage(this.currentIndex);
        },
        goBack() {
            this.$router.go(-1);
        },
        handleRating() {
            this.ratingDialog = !this.ratingDialog;
        }
    }
};
</script>
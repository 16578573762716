<template>
    <div class="container">
        <div class="product-details-tab product-details-extended" v-if="product != null">
            <!-- tabs -->
            <tabs class="nav-pills" :data="tabsData"></tabs>
            <div class="tab-content border-bottom-0">
                <div class="tab-pane fade show active" id="product_description_full">
                    <div class="product-desc-content">
                        <div class="product-desc-row" v-if="product.description.html !== ''">
                            <div class="container">
                                <div class="row">
                                    <div class="col-md-12">
                                        <p v-html="product.description.html"></p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div v-else>
                            <p>No Description were found</p>
                        </div>
                    </div>
                </div>

                <div class="tab-pane fade" id="extra_information">
                    <div class="product-desc-content">
                        <div class="container" v-if="product.information_attributes.length > 0">
                            <table class="table table-striped">
                                <tbody>
                                    <tr v-for="item in product.information_attributes" :key="item.attribute_id">
                                        <td>{{ item.label }}</td>
                                        <td>{{ item.value }}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>

                <div class="tab-pane fade" id="request_information">
                    <div class="product-desc-content">
                        <div class="container">
                            <h3 class="mt-1">{{ form.title }}</h3>
                            <form action="#">
                                <div class="row">
                                    <div class="col-md-12">
                                    </div>
                                    <div class="col-md-6">
                                        <div class="form-group" v-for="(field, i) in form.fieldsets[0].fields" :key="i">
                                            <label :for="field.name">{{ field.label }} *</label>
                                            <input :type="field.inputType" :value="field.value"
                                                :placeholder="field.placeholder" class="form-control" :id="field.name"
                                                v-model="formData[field.name]" :name="field.name" required />
                                        </div>

                                        <!-- <div class="form-group">
                                            <label for="email">{{ $t('email') }} *</label>
                                            <input type="email" class="form-control" id="email" name="email"
                                                :placeholder="$t('email')" required />
                                        </div>

                                        <div class="form-group">
                                            <label for="telephone">{{ $t('telefoonnumber') }}</label>
                                            <input type="tel" class="form-control" id="telephone" name="telephone"
                                                placeholder="9286354198" />
                                        </div> -->
                                    </div>
                                    <div class="col-md-6">
                                        <div class="form-group" v-for="(field, i) in form.fieldsets[1].fields" :key="i">
                                            <label :for="field.name">{{ field.label }} *</label>
                                            <textarea :type="field.type" class="form-control" :id="field.name"
                                                :value="field.value" :name="field.name" :placeholder="field.placeholder"
                                                v-model="formData[field.name]" required rows="10"></textarea>
                                        </div>
                                        <div class="form-footer float-right">
                                            <button type="button" @click="sendForm" class="btn btn-outline-primary-2">
                                                <span>{{ $t('send2') }}</span>
                                                <i class="icon-long-arrow-right"></i>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>

                <div class="tab-pane fade" id="downloads">
                    <div class="product-desc-content" v-if="product.downloads.length">
                        <div class="container">
                            <div class="row">
                                <div class="col-md-1 col-3">
                                    <img src="@/esf_weert_mobilesupplies/assets/static/images/PDF_file_icon.png" alt="">
                                    <p class="d-none d-md-block">ebruiksaan-wijzing.pdf</p>
                                    <small class="d-block d-md-none">ebruiksaan-wijzing.pdf</small>
                                </div>
                                <div class="col-md-1 col-3">
                                    <img src="@/esf_weert_mobilesupplies/assets/static/images/PDF_file_icon.png" alt="">
                                    <p class="d-none d-md-block">ebruiksaan-wijzing.pdf</p>
                                    <small class="d-block d-md-none">ebruiksaan-wijzing.pdf</small>
                                </div>
                                <div class="col-md-1 col-3">
                                    <img src="@/esf_weert_mobilesupplies/assets/static/images/PDF_file_icon.png" alt="">
                                    <p class="d-none d-md-block">ebruiksaan-wijzing.pdf</p>
                                    <small class="d-block d-md-none">ebruiksaan-wijzing.pdf</small>
                                </div>
                                <div class="col-md-1 col-3">
                                    <img src="@/esf_weert_mobilesupplies/assets/static/images/PDF_file_icon.png" alt="">
                                    <p class="d-none d-md-block">ebruiksaan-wijzing.pdf</p>
                                    <small class="d-block d-md-none">ebruiksaan-wijzing.pdf</small>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="product-desc-content" v-else>
                        No Downloads available
                    </div>
                </div>

                <div class="tab-pane fade" id="reviews">
                    <div class="reviews">
                        <div class="container">
                            <h3 class="mt-1">{{ $t('product_review') }} ({{ product.review_count }})</h3>
                            <div class="row">
                                <div class="col-md-8">
                                    <div v-if="product.reviews.items.length">
                                        <div class="review" v-for="item in product.reviews.items.slice(0, 1)"
                                            :key="item.id">
                                            <div class="row no-gutters">
                                                <div class="col-md-12 mb-1">
                                                    <!-- <h4>
                                                        <a href="#">Samanta J.</a>
                                                    </h4> -->
                                                    <div class="ratings-container"
                                                        v-for="(breakdown, index) in item.ratings_breakdown"
                                                        :key="index">
                                                        <p class="mr-1" style="width: 7rem"><strong>{{ breakdown.name
                                                                }}</strong></p>
                                                        <div class="ratings">
                                                            <div class="ratings-val"
                                                                :style="{ width: breakdown.value * 20 + '%' }">
                                                            </div>
                                                            <span class="tooltip-text">{{ breakdown.value }}</span>
                                                        </div>
                                                    </div>
                                                    <!-- <div class="ratings-container">
                                                        <p class="mr-1" style="width: 7rem"><strong>{{ $t('price')
                                                                }}</strong></p>
                                                        <div class="ratings">
                                                            <div class="ratings-val" style="width: 80%;"></div>
                                                            <span class="tooltip-text">4</span>
                                                        </div>
                                                    </div> -->

                                                    <span class="review-date">
                                                        <!-- {{ $t('reviewed_date', { date: item.created_at }) }} -->
                                                        Gereviewed door <strong>{{ item.nickname }}</strong> on
                                                        {{ formatDate(item.created_at) }}:
                                                    </span>
                                                </div>

                                                <div class="col-md-12">
                                                    <!-- <h4>Good, perfect size</h4> -->
                                                    <div class="review-content">
                                                        <p><q> {{ item.summary }}</q></p>
                                                    </div>

                                                    <!-- <div class="review-action">
                                                        <a href="#">
                                                            <i class="icon-thumbs-up"></i>Helpful (2)
                                                        </a>
                                                        <a href="#">
                                                            <i class="icon-thumbs-down"></i>Unhelpful (0)
                                                        </a>
                                                    </div> -->
                                                </div>
                                            </div>
                                        </div>
                                        <p class="text-right" @click="dialogVisible = true">
                                            <strong class="pointer">{{ $t('view_all_reviews') }}</strong>
                                        </p>
                                    </div>
                                    <div v-else>
                                        <p>No Reviews were found</p>
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <overall-rating :product="product" />
                                </div>
                            </div>
                            <div class="row mt-md-0 mt-2">
                                <div class="col-md-12">
                                    <form action="#" @submit.prevent="addReview">
                                        <div class="row">
                                            <div class="col-md-12">
                                                <h6>{{ $t('write_a_review') }}</h6>
                                            </div>
                                            <div class="col-md-6">
                                                <div class="col-md-12 mb-1">
                                                    <div class="d-flex align-items-center">
                                                        <span>{{ $t('number_of_stars') }}</span>
                                                        <rate-comp v-model="ratings"
                                                            @update:value="handleQualityRate" />
                                                        <span>{{ $t('quality') }}<span
                                                                class="text-danger">*</span></span>
                                                    </div>
                                                    <div class="d-flex align-items-center">
                                                        <span>{{ $t('number_of_stars') }}</span>
                                                        <rate-comp v-model="ratings" @update:value="handlePriceRate" />
                                                        <span>{{ $t('price') }}<span class="text-danger">*</span></span>
                                                    </div>
                                                </div>
                                                <div class="form-group">
                                                    <label for="Naam">{{ $t('form_name') }}<span
                                                            class="text-danger">*</span></label>
                                                    <input type="text" class="form-control" id="Naam" name="Naam"
                                                        v-model="reviewForm.nickname" :placeholder="$t('form_name')"
                                                        required />
                                                </div>

                                                <div class="form-group">
                                                    <label for="email">{{ $t('email') }} (stays secret)<span
                                                            class="text-danger">*</span></label>
                                                    <input type="email" class="form-control" id="email" name="email"
                                                        :placeholder="$t('email')" required />
                                                </div>
                                            </div>
                                            <div class="col-md-6">
                                                <div class="form-group">
                                                    <label for="ask">{{ $t('describe_your_experience') }}</label>
                                                    <textarea class="form-control" id="ask" name="ask" rows="8"
                                                        v-model="reviewForm.summary" required></textarea>
                                                </div>
                                                <div class="form-footer float-right">
                                                    <button type="submit" class="btn btn-success">
                                                        <span>{{ $t('send2') }}</span>
                                                    </button>

                                                    <!-- <div class="custom-control custom-checkbox">
                                                <input type="checkbox" class="custom-control-input"
                                                    id="signin-remember" />
                                                <label class="custom-control-label" for="signin-remember">Remember
                                                    Me</label>
                                            </div>

                                            <a href="javascript:;" class="forgot-link">Forgot Your Password?</a> -->
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- tabs -->
        </div>

        <dialog-box width="95%" :title="$t('product_review') + ' (' + product.review_count + ')'"
            :isVisible="dialogVisible" :handleClose="handleClose">
            <template v-slot:default>
                <div v-if="product.reviews.items.length">
                    <div class="review" v-for="item in  product.reviews.items " :key="item.id">
                        <div class="row no-gutters">
                            <div class="col-md-12 mb-1">
                                <div class="ratings-container" v-for="(breakdown, index) in  item.ratings_breakdown"
                                    :key="index">
                                    <p class="mr-1" style="width: 7rem"><strong>{{ breakdown.name }}</strong></p>
                                    <div class="ratings">
                                        <div class="ratings-val" :style="{ width: breakdown.value * 20 + '%' }"></div>
                                        <span class="tooltip-text">{{ breakdown.value }}</span>
                                    </div>
                                </div>
                                <!-- <div class="ratings-container">
                                    <p class="mr-1" style="width: 7rem"><strong>{{ $t('price') }}</strong></p>
                                    <div class="ratings">
                                        <div class="ratings-val" style="width: 80%;"></div>
                                        <span class="tooltip-text">4</span>
                                    </div>
                                </div> -->

                                <span class="review-date">
                                    <!-- Gereviewed door <strong>Jan</strong> op 01-01-21: -->
                                    {{ $t('reviewed_date', { date: item.created_at }) }}
                                </span>
                            </div>

                            <div class="col-md-12">
                                <!-- <h4>Good, perfect size</h4> -->
                                <div class="review-content">
                                    <p><q> {{ item.summary }}</q></p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div v-else>
                    <p>No Reviews were found</p>
                </div>
            </template>
        </dialog-box>

        <b-modal id="modal-dynamic_form" okVariant="success" okTitle="Ok" @ok="clickDynFormOk">
            <div v-html="msg"></div>
        </b-modal>
    </div>
</template>
<script>
import Tabs from '@/esf_weert_mobilesupplies/core/components/elements/TabsDefault';
import OverallRating from '@/esf_weert_mobilesupplies/core/components/elements/OverallRating';
import RateComp from '@/esf_weert_mobilesupplies/core/components/elements/RateComp';
import DialogBox from '@/esf_weert_mobilesupplies/core/components/elements/DialogBox';
import { sendForm } from "@storefront/core/data-resolver/forms";
import { mapGetters } from "vuex";
export default {
    components: {
        Tabs,
        OverallRating,
        RateComp,
        DialogBox,
    },
    data: function () {
        return {
            type: "product",
            ratings: [],
            reviewForm: {
                nickname: "",
                summary: "",
                text: ""
            },
            formFields: [],
            formData: {},
            alpha_numeric: /^[a-zA-Z0-9]+$/,
            alpha_numeric_with_spaces: /^[a-zA-Z0-9 ]+$/,
            letters_only: /^[a-zA-Z]+$/,
            url: /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/,
            numeric: /^\d+$/,
            numeric_with_decimals: /^\d+(?:\.|,)\d+$/,
            disallow_html_tags: /(<([^>]+)>)/gi,
            emailaddress:
                /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/,
            msg: "",
            // requestInformation: {
            //     name: "",
            //     email: "",
            //     phone: "",
            //     question: ""
            // },
            tabsData: [
                {
                    id: 'product_description_full',
                    title: 'product_description_full',
                    active: true
                },
                {
                    id: 'extra_information',
                    title: 'extra_information'
                },
                {
                    id: 'request_information',
                    title: 'request_information'
                },
                {
                    id: 'downloads',
                    title: 'downloads'
                },
                {
                    id: 'reviews',
                    title: 'product_review'
                }
            ],
            // reviews: [
            //     {
            //         id: 1,
            //         name: 'Samanta J.',
            //         quality: 4,
            //         price: 4,
            //         date: '01-01-21',
            //         review: 'Good, perfect size'
            //     },
            //     {
            //         id: 2,
            //         name: 'Samanta J.',
            //         quality: 4,
            //         price: 4,
            //         date: '01-01-21',
            //         review: 'Good, perfect size'
            //     },
            //     {
            //         id: 3,
            //         name: 'Samanta J.',
            //         quality: 4,
            //         price: 4,
            //         date: '01-01-21',
            //         review: 'Good, perfect size'
            //     }
            // ],
            dialogVisible: false,
            // priceRate: 0,
            // qualityRate: 0
        };
    },
    computed: {
        ...mapGetters({
            product: "product/getCurrentProduct",
            // productForm: "forms/getProductForm",
            reviewMeta: "product/getProductReviewRatingsMetadata",
        }),
        remote_entity_id() {
            if (this.type == "product") {
                return this.product.sku;
            } else if (this.type == "category") {
                return 'remote_entity_id: "' + this.category_id + '" ';
            }
            return "";
        },
        form() {
            if (this.type == "product") {
                return this.$store.getters["forms/getProductForm"];
            } else if (this.type == "category") {
                return this.$store.getters["forms/getCategoryForm"];
            }
            return this.$store.getters["forms/getContactForm"];
        },
        // reviews: this.product.reviews.items
    },
    created() {
        this.formFields = this.form.fieldsets[0].fields;
        this.formFields = [...this.formFields, ...this.form.fieldsets[1].fields];
        this.formFields.forEach(field => {
            this.$set(this.formData, field.name, field.value);
        });
    },
    methods: {
        formatDate(dateString) {
            const date = new Date(dateString);
            return date.toLocaleDateString('en-GB').split('/').join('-');
        },
        clickDynFormOk() {
            this.$bvModal.hide("modal-dynamic_form");
        },
        async addReview() {
            // console.log('this.ratings', this.ratings)
            const rat = this.ratings.map((e) => ({ id: e.value, value_id: e.value_id }))
            const retval = await this.$store.dispatch("productSerie/postReviews", {
                form: { ...this.reviewForm, sku: this.product.sku, ratings: rat },
            });
            // console.log('retval', retval);
            if (retval) {
                const msg = {
                    type: "success",
                    title: "Review",
                    text: "Your review has been successfully added!",
                };
                this.$store.dispatch("messages/sendMessage", { message: msg });
                this.reviewForm = {
                    nickname: "",
                    summary: "",
                    text: ""
                }
                this.ratings = [];
            }
        },
        // async submitForm() {
        //     let formattedData = {
        //         field: Object.keys(this.formData).map(name => ({
        //             name: name,
        //             value: this.formData[name]
        //         }))
        //     };
        //     formattedData = Object.assign(formattedData, { type: "product" })
        //     // console.log('formattedData', formattedData);
        //     const retval = await this.$store.dispatch("forms/postForm", { form: { ...formattedData } });
        //     console.log('retval', retval);
        // },
        async sendForm() {
            // this.form.fieldsets.forEach((set, idx) => {
            //     set.fields.forEach((field, index) => {
            //         let state = true;
            //         let error = "";
            //         field.validation.forEach((val) => {
            //             if (val.type == "required") {
            //                 if (this.formValue[idx][index] == "") {
            //                     state = false;
            //                     error = this.$t("dyform_required");
            //                 }
            //             } else if (val.type == "alpha_numeric") {
            //                 if (this.alpha_numeric.test(this.formValue[idx][index]) != true) {
            //                     state = false;
            //                     error = this.$t("dyform_alpha_numeric");
            //                 }
            //             } else if (val.type == "alpha_numeric_with_spaces") {
            //                 if (
            //                     this.alpha_numeric_with_spaces.test(
            //                         this.formValue[idx][index]
            //                     ) != true
            //                 ) {
            //                     state = false;
            //                     error = this.$t("dyform_alpha_numeric_with_spaces");
            //                 }
            //             } else if (val.type == "letters_only") {
            //                 if (this.letters_only.test(this.formValue[idx][index]) != true) {
            //                     state = false;
            //                     error = this.$t("dyform_letters_only");
            //                 }
            //             } else if (val.type == "disallow_html_tags") {
            //                 if (
            //                     this.disallow_html_tags.test(this.formValue[idx][index]) == true
            //                 ) {
            //                     state = false;
            //                     error = this.$t("dyform_disallow_html_tags");
            //                 }
            //             } else if (val.type == "email") {
            //                 if (this.emailaddress.test(this.formValue[idx][index]) != true) {
            //                     state = false;
            //                     error = this.$t("dyform_emailaddress");
            //                 }
            //             } else if (val.type == "url") {
            //                 if (this.url.test(this.formValue[idx][index]) != true) {
            //                     state = false;
            //                     error = this.$t("dyform_url");
            //                 }
            //             } else if (val.type == "min_text_length") {
            //                 if (
            //                     this.formValue[idx][index].length < parseInt(val.additional)
            //                 ) {
            //                     state = false;
            //                     error = this.$t("dyform_min_text_length", {
            //                         aantal: parseInt(val.additional),
            //                     });
            //                 }
            //             } else if (val.type == "max_text_length") {
            //                 if (
            //                     this.formValue[idx][index].length > parseInt(val.additional)
            //                 ) {
            //                     state = false;
            //                     error = this.$t("max_text_length", {
            //                         aantal: parseInt(val.additional),
            //                     });
            //                 }
            //             } else if (val.type == "numeric") {
            //                 if (this.numeric.test(this.formValue[idx][index]) != true) {
            //                     state = false;
            //                     error = this.$t("dyform_numeric");
            //                 }
            //             } else if (val.type == "numeric_with_decimals") {
            //                 if (
            //                     this.numeric_with_decimals.test(this.formValue[idx][index]) !=
            //                     true
            //                 ) {
            //                     state = false;
            //                     error = this.$t("dyform_numeric_with_decimals");
            //                 }
            //             } else if (val.type == "greater_then_or_equal_to") {
            //                 //
            //             } else if (val.type == "less_then_or_equal_to") {
            //                 //
            //             }
            //         });
            //         this.$set(this.formState[idx], index, state);
            //         this.$set(this.formError[idx], index, error);
            //     });
            // });
            let save = true;
            // this.form.fieldsets.forEach((set, idx) => {
            //     set.fields.forEach((field, index) => {
            //         if (this.formState[idx][index] != true) {
            //             save = false;
            //         }
            //     });
            // });
            if (save == true) {
                //send form
                let formattedData = {
                    field: Object.keys(this.formData).map(name => ({
                        name: name,
                        value: this.formData[name]
                    }))
                };
                formattedData = Object.assign(formattedData, { type: "product" })
                formattedData = Object.assign(formattedData, { remote_entity_id: this.remote_entity_id })
                console.log('formattedData', formattedData);
                let input =
                    'type:' +
                    formattedData.type +
                    ' remote_entity_id: "' +
                    formattedData.remote_entity_id +
                    '" ' +
                    " field: [";
                formattedData.field.forEach((element) => {
                    input +=
                        "{ name: " +
                        JSON.stringify(element.name) +
                        ", value: " +
                        JSON.stringify(element.value) +
                        "} ";
                });
                //     "type: " + this.type + " " + this.remote_entity_id + " field: [";
                // this.form.fieldsets.forEach((set, idx) => {
                //     set.fields.forEach((field, index) => {
                //         input =
                //             input +
                //             '{ name: "' +
                //             field.name +
                //             '", value: "' +
                //             this.formValue[idx][index] +
                //             '" }';
                //     });
                // });
                input += "]";
                const retval = await sendForm(input);
                if (retval.has_errors == true) {
                    //
                } else {
                    this.msg = retval.thank_you_message;
                    this.$bvModal.show("modal-dynamic_form");
                    this.form.fieldsets.forEach((set, idx) => {
                        set.fields.forEach((field, index) => {
                            this.$set(this.formValue[idx], index, "");
                            this.$set(this.formState[idx], index, null);
                            this.$set(this.formError[idx], index, "");
                        });
                    });
                }
            }
        },
        handlePriceRate(newValue) {
            // this.priceRate = newValue;
            this.reviewMeta.map((item) => {
                if (item.name === "Prijs") {
                    // console.log('reviewMeta', item)
                    this.ratings = [...this.ratings, { value: item.id, value_id: item.values[(newValue - 1)].value_id }];
                }
            })
        },
        handleQualityRate(newValue) {
            // this.qualityRate = newValue;
            this.reviewMeta.map((item) => {
                // console.log('reviewMeta', item)
                if (item.name === "Kwaliteit") {
                    this.ratings = [...this.ratings, { value: item.id, value_id: item.values[(newValue - 1)].value_id }];
                }
            })
        },
        handleClose() {
            this.dialogVisible = false;
        }
    }
};
</script>